import { graphql } from "gatsby";
import { Helmet, Link, Trans } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import Layout from "../../components/nigeria/layout";
import { Nigeria_MetaTags } from "../../helpers/constants";
// import AwardIcon from "../../images/nigeria/home/award.png";
import { StaticImage } from "gatsby-plugin-image";
import GetStartedComponent from "../../components/GetStartedComponent";
import SwiperComponent from "../../components/swiper";
import {
  AcceptIcon,
  ArrowRightShortIcon,
  CardIcon,
  CircularProgress,
  ClockIcon,
  EasyOsIcon,
  FreeAccountIcon,
  GetVerifiedIcon,
  InterestRateIcon,
  LoansIcon,
  NoCollateralIcon,
  NotificationIcon,
  PhoneIcon,
  PosIcon,
  RepeatIcon,
  SecurityShieldIcon,
  SpendingLimitIcon,
  SupportIcon,
  TrackBooksIcon,
} from "../../images/nigeria/Icons";

import useScrollPosition from "../../hooks/useScrollPosition";
import AwardIcon from "../../images/home/award-badge.png";
import {
  ControlIcon,
  HiddenFeesIcon,
  InstantIcon,
  LockIcon,
} from "../../images/nigeria/Icons";
import HeroVideo from "../../images/nigeria/home/hero.mp4";
import HeroImage from "../../images/nigeria/home/hero.png";

const HomePage = ({ data }) => {
  const scrollRef = useScrollPosition();
  const businessTips = data.businessTips.nodes;
  return (
    <Layout
      pageTitle={Nigeria_MetaTags.homepage.title}
      description={Nigeria_MetaTags.homepage.description}
      bankingType={'business'}
    >
      <Helmet>
        <link rel="canonical" href="https://moniepoint.com/ng" />
        <link rel="alternate" href="https://moniepoint.com/ng" hreflang="x-default" />
        <link rel="alternate" href="https://moniepoint.com/ng" hreflang="en-ng" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Moniepoint Nigeria",
  "description": "We are a financial services company based in Nigeria, offering a range of business banking services including business accounts, business loans, pos terminal machines, expense cards, and more.",
  "image": "https://moniepoint.com/static/MFB-667f089014bfae5d706fc620b07be2e0.png",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "12, Wole Ariyo Street.",
    "addressLocality": "Lekki Phase 1",
    "addressRegion": "Lagos",
    "postalCode": "106104",
    "addressCountry": "NG"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 6.44300,
    "longitude": 3.45908
  },
  "url": "https://moniepoint.com/ng/",
  "telephone": "+01 888 9990",
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "08:00",
    "closes": "17:00"
  },
  "sameAs": [
    "https://www.facebook.com/Moniepointng/",
    "https://instagram.com/moniepointng",
    "https://twitter.com/moniepointng",
    "https://www.linkedin.com/showcase/moniepoint/"
  ]
}`}
        </script>
      </Helmet>
      <HeroContainer mdSrc={HeroImage}>
        <video
          autoPlay
          loop
          className="hero-video"
          muted
          playsInline
          onContextMenu={(e) => { e.stopPropagation(); }}
          preload="auto"
        >
          <source src={HeroVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        <div className="hero-mask"></div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <div className="award-badge">
              <img src={AwardIcon} height="27" alt={''} className="award-badge-image" />
              <span>
                <Trans>homepage.award_text</Trans>
              </span>
            </div>
            <h1 className="hero-title">
              <Trans>homepage.hero_title</Trans>
            </h1>
            <p className="hero-subtitle">
              <Trans>homepage.hero_subtitle</Trans>
            </p>
            <a
              href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2"
              target="_blank"
              className="btn btn-primary"
            >
              <Trans>homepage.hero_cta</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
        </div>
      </HeroContainer>
      <ToolsConatiner>
        <div className="content">
          <h3 className="title">
            <Trans>homepage.tools_title</Trans>
          </h3>
          <div className="tools-list">
            <Link to="/ng/business-account" className="tools-item">
              <StaticImage
                loading="lazy"
                class="tool-image"
                src="../../images/nigeria/home/tools-1.png"
              />
              <p className="tool-name">
                <Trans>homepage.tool_1</Trans>
              </p>
            </Link>
            <Link to="/ng/payment/pos-terminals" className="tools-item">
              <StaticImage
                loading="lazy"
                class="tool-image"
                src="../../images/nigeria/home/tools-2.png"
              />
              <p className="tool-name">
                <Trans>homepage.tool_2</Trans>
              </p>
            </Link>
            <Link to="/ng/expense-cards" className="tools-item">
              <StaticImage
                loading="lazy"
                class="tool-image"
                src="../../images/nigeria/home/tools-3.png"
              />
              <p className="tool-name">
                <Trans>homepage.tool_3</Trans>
              </p>
            </Link>
            <Link to="/ng/working-capital-loans" className="tools-item">
              <StaticImage
                loading="lazy"
                class="tool-image"
                src="../../images/nigeria/home/tools-4.png"
              />
              <p className="tool-name">
                <Trans>homepage.tool_4</Trans>
              </p>
            </Link>
          </div>
        </div>
      </ToolsConatiner>
      <SwiperContainer ref={scrollRef}>
        <div className="sticky-container">
          <div className="top-content">
            <div className="timeline">
              <div className="timeline-item tl-1 active">
                <div className="progress-element">
                  <CircularProgress id={1} />
                  <PhoneIcon />
                </div>
                <div className="timeline-track">
                  <div className="timeline-track-item"></div>
                </div>
              </div>
              <div className="timeline-item tl-2">
                <div className="progress-element">
                  <CircularProgress id={2} />
                  <PosIcon size={32} />
                </div>
                <div className="timeline-track">
                  <div className="timeline-track-item"></div>
                </div>
              </div>
              <div className="timeline-item tl-3">
                <div className="progress-element">
                  <CircularProgress id={3} />
                  <CardIcon size={32} />
                </div>
                <div className="timeline-track">
                  <div className="timeline-track-item"></div>
                </div>
              </div>
              <div className="timeline-item tl-4 last">
                <div className="progress-element">
                  <CircularProgress id={4} />
                  <LoansIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="timeline-body">
            <div className="timeline-body-item timeline-body-1">
              <div className="flex-container">
                <div className="text-section">
                  <h3 className="title">
                    <Trans>homepage.slides.slide_1_title</Trans>
                  </h3>
                  <div className="features-list">
                    <div className="feature-item">
                      <FreeAccountIcon />
                      <span>
                        <Trans>business_account_page.feature_1</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <GetVerifiedIcon />
                      <span>
                        <Trans>business_account_page.feature_2</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <HiddenFeesIcon />
                      <span>
                        <Trans>business_account_page.feature_3</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <ControlIcon />
                      <span>
                        <Trans>business_account_page.feature_4</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <SupportIcon />
                      <span>
                        <Trans>business_account_page.feature_5</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <NotificationIcon />
                      <span>
                        <Trans>business_account_page.feature_6</Trans>
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    <Trans>business_account_page.features_cta</Trans>
                    <ArrowRightShortIcon size={24} />
                  </a>
                </div>
                <div className="image-section">
                  <StaticImage
                    loading="eager"
                    className=""
                    src="../../images/nigeria/home/slide-1.png"
                  />
                </div>
              </div>
            </div>
            <div className="timeline-body-item timeline-body-2">
              <div className="flex-container">
                <div className="text-section">
                  <h3 className="title">
                    <Trans>homepage.slides.slide_2_title</Trans>
                  </h3>
                  <div className="features-list">
                    <div className="feature-item">
                      <LockIcon />
                      <span>
                        <Trans>pos_page.feature_item_1</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <EasyOsIcon />
                      <span>
                        <Trans>pos_page.feature_item_2</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <SupportIcon />
                      <span>
                        <Trans>pos_page.feature_item_3</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <CardIcon />
                      <span>
                        <Trans>pos_page.feature_item_4</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <InstantIcon />
                      <span>
                        <Trans>pos_page.feature_item_5</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <AcceptIcon />
                      <span>
                        <Trans>pos_page.feature_item_6</Trans>
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://airtable.com/shrR9t7GKBuudRzQ7"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    <Trans>join_waitlist</Trans>
                    <ArrowRightShortIcon size={24} />
                  </a>
                </div>
                <div className="image-section">
                  <StaticImage
                    loading="eager"
                    className=""
                    src="../../images/nigeria/home/slide-2.png"
                  />
                </div>
              </div>
            </div>
            <div className="timeline-body-item timeline-body-3">
              <div className="flex-container">
                <div className="text-section">
                  <h3 className="title">
                    <Trans>homepage.slides.slide_3_title</Trans>
                  </h3>
                  <div className="features-list">
                    <div className="feature-item">
                      <PosIcon />
                      <span>
                        <Trans>expense_card_page.feature_1</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <ClockIcon />
                      <span>
                        <Trans>expense_card_page.feature_2</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <SpendingLimitIcon />
                      <span>
                        <Trans>expense_card_page.feature_3</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <TrackBooksIcon />
                      <span>
                        <Trans>expense_card_page.feature_4</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <SecurityShieldIcon />
                      <span>
                        <Trans>expense_card_page.feature_5</Trans>
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    <Trans>expense_card_page.get_expense_card</Trans>
                    <ArrowRightShortIcon size={24} />
                  </a>
                </div>
                <div className="image-section">
                  <StaticImage
                    loading="eager"
                    className=""
                    src="../../images/nigeria/home/slide-3.png"
                  />
                </div>
              </div>
            </div>
            <div className="timeline-body-item timeline-body-4">
              <div className="flex-container">
                <div className="text-section">
                  <h3 className="title">
                    <Trans>homepage.slides.slide_4_title</Trans>
                  </h3>
                  <div className="features-list">
                    <div className="feature-item">
                      <NoCollateralIcon />
                      <span>
                        <Trans>credits_page.feature_1</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <InterestRateIcon />
                      <span>
                        <Trans>credits_page.feature_2</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <RepeatIcon />
                      <span>
                        <Trans>credits_page.feature_3</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <CardIcon />
                      <span>
                        <Trans>credits_page.feature_4</Trans>
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    <Trans>apply_for_loan</Trans>
                    <ArrowRightShortIcon size={24} />
                  </a>
                </div>
                <div className="image-section">
                  <StaticImage
                    loading="eager"
                    className=""
                    src="../../images/nigeria/home/slide-4.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperContainer>
      <SwiperSmConatiner>
        <div className="content">
          <div className="feature-container">
            <div className="subtitle">
              <Trans>Business Account</Trans>
            </div>
            <h3 className="title">
              <Trans>homepage.slides.slide_1_title</Trans>
            </h3>
            <StaticImage
              className="image-container"
              src="../../images/nigeria/home/slide-1-sm.png"
            />
            <div className="features-list">
              <div className="feature-item">
                <FreeAccountIcon />
                <span>
                  <Trans>business_account_page.feature_1</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <GetVerifiedIcon />
                <span>
                  <Trans>business_account_page.feature_2</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <HiddenFeesIcon />
                <span>
                  <Trans>business_account_page.feature_3</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <ControlIcon />
                <span>
                  <Trans>business_account_page.feature_4</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <SupportIcon />
                <span>
                  <Trans>business_account_page.feature_5</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <NotificationIcon />
                <span>
                  <Trans>business_account_page.feature_6</Trans>
                </span>
              </div>
            </div>
            <a
              href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2"
              target="_blank"
              className="btn btn-primary"
            >
              <Trans>business_account_page.features_cta</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
          <div className="feature-container">
            <div className="subtitle">
              <Trans>POS Terminals</Trans>
            </div>
            <h3 className="title">
              <Trans>homepage.slides.slide_2_title</Trans>
            </h3>
            <StaticImage
              className="image-container"
              src="../../images/nigeria/home/slide-2-sm.png"
            />
            <div className="features-list">
              <div className="feature-item">
                <LockIcon />
                <span>
                  <Trans>pos_page.feature_item_1</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <EasyOsIcon />
                <span>
                  <Trans>pos_page.feature_item_2</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <SupportIcon />
                <span>
                  <Trans>pos_page.feature_item_3</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <CardIcon />
                <span>
                  <Trans>pos_page.feature_item_4</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <InstantIcon />
                <span>
                  <Trans>pos_page.feature_item_5</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <AcceptIcon />
                <span>
                  <Trans>pos_page.feature_item_6</Trans>
                </span>
              </div>
            </div>
            <a
              href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2"
              target="_blank"
              className="btn btn-primary"
            >
              <Trans>pos_page.features_cta</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
          <div className="feature-container">
            <div className="subtitle">
              <Trans>Expense Cards</Trans>
            </div>
            <h3 className="title">
              <Trans>homepage.slides.slide_3_title</Trans>
            </h3>
            <StaticImage
              className="image-container"
              src="../../images/nigeria/home/slide-3-sm.png"
            />
            <div className="features-list">
              <div className="feature-item">
                <PosIcon />
                <span>
                  <Trans>expense_card_page.feature_1</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <ClockIcon />
                <span>
                  <Trans>expense_card_page.feature_2</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <SpendingLimitIcon />
                <span>
                  <Trans>expense_card_page.feature_3</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <TrackBooksIcon />
                <span>
                  <Trans>expense_card_page.feature_4</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <SecurityShieldIcon />
                <span>
                  <Trans>expense_card_page.feature_5</Trans>
                </span>
              </div>
            </div>
            <a
              href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2"
              target="_blank"
              className="btn btn-primary"
            >
              <Trans>expense_card_page.get_expense_card</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
          <div className="feature-container">
            <div className="subtitle">
              <Trans>Working Capital Loans</Trans>
            </div>
            <h3 className="title">
              <Trans>homepage.slides.slide_4_title</Trans>
            </h3>
            <StaticImage
              className="image-container"
              src="../../images/nigeria/home/slide-4-sm.png"
            />
            <div className="features-list">
              <div className="feature-item">
                <NoCollateralIcon />
                <span>
                  <Trans>credits_page.feature_1</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <InterestRateIcon />
                <span>
                  <Trans>credits_page.feature_2</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <RepeatIcon />
                <span>
                  <Trans>credits_page.feature_3</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <CardIcon />
                <span>
                  <Trans>credits_page.feature_4</Trans>
                </span>
              </div>
            </div>
            <a
              href="https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2"
              target="_blank"
              className="btn btn-primary"
            >
              <Trans>apply_for_loan</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
        </div>
      </SwiperSmConatiner>
      <GetStartedComponent
        title="homepage.get_started_title_1"
        bgColor={'rgba(3, 87, 238, 0.03)'}
        color={'#000000'}
      >
        <div className="image-list">
          <StaticImage
            alt="..."
            src="../../images/nigeria/home/business-1.png"
            style={{}}
          />
          <StaticImage
            alt="..."
            src="../../images/nigeria/home/business-2.png"
            style={{}}
          />
          <StaticImage
            alt="..."
            src="../../images/nigeria/home/business-3.png"
            style={{}}
          />
          <StaticImage
            alt="..."
            src="../../images/nigeria/home/business-4.png"
            style={{}}
          />
          <StaticImage
            alt="..."
            src="../../images/nigeria/home/business-5.png"
            style={{}}
          />
        </div>
      </GetStartedComponent>
      <VideoConatiner>
        <div className="content">
          <h3 className="title">
            <Trans>homepage.video_title</Trans>
          </h3>
          <iframe
            className="video-frame"
            width="100%"
            src="https://www.youtube.com/embed/dM3NmmXb6K4"
            title="Tobi Hamilton on Moniepoint - The House"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            // allowFullScreen
          ></iframe>
        </div>
      </VideoConatiner>
      <LearnAllContainer>
        <div className="content">
          <h3 className="title">
            <Trans>homepage.learn_all_title</Trans>
          </h3>
          <div className="swiper-section">
            <SwiperComponent
              loop
              slidesPerView={"auto"}
              mousewheel={{ releaseOnEdges: true }}
              width={348}
              autoplay
              breakpoints={{
                768: {
                  spaceBetween: 32,
                  width: 304,
                },
              }}
            >
              {
                businessTips?.map((post, i) => (
                  <a key={i} target="_blank" href={`/blog/${post?.slug}`} className="learn-all-card grow">
                    <div className="card-img">
                      <img
                        loading="eager"
                        alt={post?.title}
                        src={post?.coverImage?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
                        className=""
                      />
                    </div>
                    <div className="card-title">
                      {post?.title}
                    </div>
                  </a>
                ))
              }
            </SwiperComponent>
          </div>
        </div>
      </LearnAllContainer>

    </Layout>
  );
};

export default HomePage;

export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    businessTips:  allGraphCmsPost(
    sort: {fields: date, order: DESC}
    filter: {category: {categoryName: {eq: "Business Tips"}}}
  ) {
    nodes {
      slug
      title
      formattedDate
      coverImage {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  }
`;

const HeroContainer = styled.section`
  background: url(${(props) => props.mdSrc});
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  min-height: 497px;
  position: relative;

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;

    .award-badge {
      display: flex;
      padding: 4px 27px 4px 16px;
      border-radius: 50px;
      background: white;
      color: #0361f0;
      gap: 4px;
      align-items: center;
      width: fit-content;
      margin-bottom: 8px;

      .award-badge-image {
        width: 32px;
        height: 32px;
      }

      span {
        font-family: "Founders Grotesk";
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 539px;
    background-position: 38%;

    .hero-video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 38%;
    }

    .hero-mask {
      background: linear-gradient(
        180deg,
        rgba(29, 29, 29, 0) 0%,
        rgba(29, 29, 29, 0.5) 79.14%
      );
    }

    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .award-badge {
        span {
          line-height: 1;
        }
      }


      .award-container {
        max-width: 167px;
        right: 20px;
        img {
          height: 167px;
        }
      }
      .hero-title {
        font-size: 40px;
        line-height: 44px;
      }
      .hero-subtitle {
        font-size: 16px;
        line-height: 25.76px;
      }
    }
  }
`;

const ToolsConatiner = styled.section`
  background-color: rgba(3, 87, 238, 0.03);

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 48px 150px 77px;
    @media only screen and (max-width: 1024px) {
      padding: 48px 60px 77px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 40px;
      font-weight: 500;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0 auto 64px;
      max-width: 666px;
    }
    .tools-list {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 16px;

      .tools-item {
        max-width: 247.28px;
        text-decoration: none;
        margin: auto;

        .tool-name {
          font-family: "Founders Grotesk";
          font-size: 24px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;
          margin-top: 16px;
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    background: white;
    .content {
      padding: 115.73px 20px 32px;

      .title {
        font-size: 24px;
        line-height: 28.32px;
        margin-bottom: 32px;
      }
      .tools-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        .tools-item {
          max-width: 177px;
          padding: 24px 16px;
          border: 1px solid rgba(3, 87, 238, 0.05);
          border-radius: 8px;
          gap: 16px;
          filter: drop-shadow(0px 1px 2px rgba(3, 97, 240, 0.1));

          .tool-name {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;

const SwiperContainer = styled.section`
  background-color: white;
  height: calc(50vw * 5);

  @media screen and (max-width: 768px) {
    display: none;
  }

  .sticky-container {
    position: sticky;
    top: 0;
    overflow-x: hidden;

    .top-content {
      position: relative;
      max-width: 1440px;
      margin: auto;
      padding: 32px 150px 32px;
      @media only screen and (max-width: 1024px) {
        padding: 64px 60px 32px;
      }

      @media screen and (max-height: 768px) {
        padding-top: 16px;
      }

      .timeline {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .timeline-item {
          position: relative;
          width: 100%;
          display: inline-flex;
          align-items: center;

          &.last {
            width: auto;
          }

          .progress-element {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;


              .progress-item {
                width: 64px;
                height: 64px;
                z-index: 1;
                transform: rotate(90deg);

                .track,
                .fill {
                  fill: rgba(0, 0, 0, 0);
                  stroke-width: 6;
                  transform: rotate(90deg)translate(0px, -80px);
                }

                .track {
                  stroke: rgba(11, 50, 117, 0.1);
                }

                .fill {
                  stroke: rgba(3, 97, 240, 1);
                  stroke-dasharray: 219.99078369140625;
                  stroke-dashoffset: -219.99078369140625;
                  transition: stroke-dashoffset 0.3s;
                }
              }

              svg:not(.progress-item) {
                position: absolute;
                path {
                  fill: rgba(11, 50, 117, 0.1);
                }
              }

          }


          &.completed,
          &.active {

            svg:not(.progress-item) path{
              fill: rgba(3, 97, 240, 1);
            }
        
          }

          .timeline-track {
            width: 100%;
            height: 4px;
            background-color: rgba(11, 50, 117, 0.1);

            .timeline-track-item {
              height: 4px;
              width: 0px;
              background-color: rgba(3, 87, 238, 1);
              max-width: 100%;
              min-width: 0%;
              transition-delay: 0.3s;
              transition: width 0.3s ease-in-out;
            }
          }
          }
        }
    }
  }

    .timeline-body {
      max-width: 100vw;
      margin: auto;
      display: flex;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;

      .timeline-body-item {
        white-space: nowrap;
        min-width: 100vw;
        align-items: center;
        @media only screen and (max-width: 1024px) {
          padding: 0 60px;
        }

        transition: transform 0s ease-in-out;

        .flex-container {
          display: flex;
          justify-content: space-between;
          max-width: 1440px;
          padding: 0 150px;
          margin: auto;
          gap: 20px;
          width: 100vw;
          @media screen and (max-height: 768px) {
            transform: scale(0.8);
            transform-origin: top center;
          }
        }

        .image-section {

          img {
            height: 100%;
            max-height: 735px;
            width: 100%;
          }
        }

        .text-section {
          max-width: 533px;
          padding-bottom: 16px;

          .title {
            font-family: "Founders Grotesk";
            font-size: 40px;
            font-weight: 500;
            line-height: 47px;
            letter-spacing: 0em;
            text-align: left;
            max-width: 533px;
            margin-bottom: 48px;
            white-space: break-spaces;
      
          }

          .features-list {
            display: flex;
            flex-direction: column;
            margin-bottom: 48px;
            max-width: 444px;

            .feature-item {
              display: flex;
              gap: 12px;
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0em;
              text-align: left;
              align-items: center;

              svg > path {
                fill: rgba(11, 50, 117, 0.5);
              }
            }

            .feature-divider {
              border: 0.8px solid rgba(3, 87, 238);
              background: rgba(3, 87, 238);
              margin: 28px 0;
              position: relative;
              opacity: 0.3;

              &:after {
                position: absolute;
                bottom: -3px;
                left: 0;
                height: 6px;
                width: 6px;
                background: rgba(3, 87, 238);
                content: "";
                border-radius: 3px;
              }

              &:after {
                right: -3px;
                left: auto;
              }
            }
          }
        }
      }
    }
`;

const SwiperSmConatiner = styled.section`
  @media only screen and (min-width: 769px) {
    display: none;
  }

  background: rgba(3, 87, 238, 0.03);

  .content {
    padding: 48px 20px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    gap: 48px;

    .feature-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .subtitle {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(3, 97, 240, 1);
        margin-bottom: 8px;
        text-transform: uppercase;
      }

      .title {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 24px;
      }

      .image-container {
        margin-bottom: 24px;
        img {
          height: 335px;
          width: auto;
        }
      }

      .features-list {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        max-width: 444px;
        width: 100%;

        .feature-item {
          display: flex;
          gap: 12px;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          align-items: center;

          svg > path {
            fill: rgba(11, 50, 117, 0.5);
          }
        }

        .feature-divider {
          border: 0.5px solid rgba(3, 87, 238);
          background: rgba(3, 87, 238);
          margin: 16px 0;
          position: relative;
          opacity: 0.3;

          &:after {
            position: absolute;
            bottom: -3px;
            left: 0;
            height: 6px;
            width: 6px;
            background: rgba(3, 87, 238);
            content: "";
            border-radius: 3px;
          }

          &:after {
            right: -3px;
            left: auto;
          }
        }
      }

      .btn.btn-primary {
        margin: 0 auto;
      }
    }
  }
`;

const VideoConatiner = styled.section`
  background: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px 84px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px 84px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 40px;
      font-weight: 500;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 24px;
    }

    .video-frame {
      border-radius: 8px;
      aspect-ratio: 16/9;
      width: 100%;
      max-width: 1140px;
      max-height: 503px;
      margin-bottom: 48px;

      .html5-video-player {
        background-color: white !important;
      }

      @supports not (aspect-ratio: 1) {
        height: 408px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px 71px;

      .title {
        font-size: 32px;
        line-height: 38px;
        max-width: 281px;
      }
      .video-frame {
        height: 311px;
        margin-bottom: 0;
      }
    }
  }
`;

const LearnAllContainer = styled.section`
  background-color: white;
  position: relative;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px 72px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px 72px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 40px;
      font-weight: 500;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: left;
    }

    .swiper-section {
      margin-top: 32px;
    }

    .learn-all-card {
      width: 348px;
      text-decoration: none;

      .card-img {
        overflow: hidden;;
        border-radius: 8px;
        margin-bottom: 24px;

        img {
          width: 348px;
        height: 187px;
        object-fit: cover;
        }
      }
      .card-title {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0,0, 0, 1)
      }
      .card-author {
        font-family: "Founders Grotesk";
        font-size: 20px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0,0, 0, 1)
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 57px 20px 48px;

      .title {
        font-size: 32px;
        line-height: 38px;
      }

      .learn-all-card {
        width: 304px;
        .card-title {
          font-size: 16px;
          line-height: 19px;
        }

        .card-author {
          display: none;
        }
      }
    }
  }
`;
