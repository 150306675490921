import { useEffect, useRef, useState } from "react";

const useScrollPosition = () => {
    const [scrollY, setScrollY] = useState(0);
    const [vw, setVw] = useState(0);
    const ref = useRef();

    function logit() {
        setScrollY(window.pageYOffset);
        setVw(Math.round(window.innerWidth));
    }

    useEffect(() => {
        const el = ref.current;

        const timelineBody1 = el.querySelector(".timeline-body-1");
        const timelineBody2 = el.querySelector(".timeline-body-2");
        const timelineBody3 = el.querySelector(".timeline-body-3");
        const timelineBody4 = el.querySelector(".timeline-body-4");
        const tl1 = el.querySelector(".tl-1");
        const tl1Track = tl1.querySelector(".timeline-track-item");
        const progress1 = tl1.querySelector(".progress-1")


        const tl2 = el.querySelector(".tl-2");
        const tl2Track = tl2.querySelector(".timeline-track-item");
        const progress2 = tl2.querySelector(".progress-2");
        const tl3 = el.querySelector(".tl-3");
        const progress3 = tl3.querySelector(".progress-3");
        const tl3Track = tl3.querySelector(".timeline-track-item");
        const tl4 = el.querySelector(".tl-4");
        const progress4 = tl4.querySelector(".progress-3");

        function hScrollAction() {
            const preScale = el.offsetTop - Math.round(scrollY)
            // let preScale = scale;
            let scale = preScale;

            const v3 = 1.5 * vw;
            const v4 = 2 * vw;
            const v5 = 2.5 * vw;

            if (scrollY < v3) {
                scale = 0;
            } else if (scrollY > v3 && scrollY < v4) {
                scale = -vw;
            } else if (scrollY > v4 && scrollY < v5) {
                scale = -2 * vw;
            } else if (scrollY > v5) {
                scale = -3 * vw;
            }

            timelineBody1.style.transform = `translate(${scale}px, 0)`;
            timelineBody2.style.transform = `translate(${scale}px, 0)`;
            timelineBody3.style.transform = `translate(${scale}px, 0)`;
            timelineBody4.style.transform = `translate(${scale}px, 0)`;

            let scale1 = Math.round(
                (1 -
                    timelineBody1.getBoundingClientRect().right /
                    timelineBody1.getBoundingClientRect().width) *
                100
            );
            let scale2 = Math.round(
                (1 -
                    timelineBody2.getBoundingClientRect().right /
                    timelineBody2.getBoundingClientRect().width) *
                100
            );
            let scale3 = Math.round(
                (1 -
                    timelineBody3.getBoundingClientRect().right /
                    timelineBody3.getBoundingClientRect().width) *
                100
            );
            let scale4 = Math.round(
                (1 -
                    timelineBody4.getBoundingClientRect().right /
                    timelineBody4.getBoundingClientRect().width) *
                100
            );

            function getPScale(scale) {
                if (scale < 0) { return 0 }
                else if (scale > 100) { return 100 }
                return scale
            }


            var max = -219.99078369140625;
            if (progress1) {
                progress1.querySelector('.fill').setAttribute('style', 'stroke-dashoffset: ' + ((100 - getPScale(scale1)) / 100) * max);
            }

            if (progress2) {
                progress2.querySelector('.fill').setAttribute('style', 'stroke-dashoffset: ' + ((100 - getPScale(scale2)) / 100) * max);
            }
            if (progress3) {
                progress3.querySelector('.fill').setAttribute('style', 'stroke-dashoffset: ' + ((100 - getPScale(scale3)) / 100) * max);
            }

            if (progress4) {
                progress3.querySelector('.fill').setAttribute('style', 'stroke-dashoffset: ' + ((100 - getPScale(scale4)) / 100) * max);
            }


            if (tl1Track) { tl1Track.style.width = `${scale1}%`; }
            if (timelineBody1.getBoundingClientRect().right < 0) {
                tl1.classList.remove("active");
                tl1.classList.add("completed");
            } else {
                tl1.classList.remove("completed");
                tl1.classList.add("active");
            }

            tl2Track.style.width = `${scale2}%`;
            if (timelineBody2.getBoundingClientRect().right < 0) {
                tl2.classList.remove("active");
                tl2.classList.add("completed");
            } else if (
                timelineBody2.getBoundingClientRect().right <
                timelineBody2.getBoundingClientRect().width
            ) {
                tl2.classList.remove("completed");
                tl2.classList.add("active");
            } else {
                tl2.classList.remove("active");
            }

            tl3Track.style.width = `${scale3}%`;
            if (timelineBody3.getBoundingClientRect().right < 0) {
                tl3.classList.remove("active");
                tl3.classList.add("completed");
            } else if (
                timelineBody3.getBoundingClientRect().right <
                timelineBody3.getBoundingClientRect().width
            ) {
                tl3.classList.remove("completed");
                tl3.classList.add("active");
            } else {
                tl3.classList.remove("active");
            }

            if (tl3Track.style.width === "100%") {
                tl4.classList.remove("active");
            }

            if (
                timelineBody4.getBoundingClientRect().right <
                timelineBody4.getBoundingClientRect().width
            ) {
                tl4.classList.remove("completed");
                tl4.classList.add("active");
            } else {
                tl4.classList.remove("active");
            }
        }

        function watchScroll() {
            window.addEventListener("scroll", logit);
            hScrollAction();
        }

        watchScroll();

        return () => {
            window.removeEventListener("scroll", logit);
        };
    });
    return ref;
};

export default useScrollPosition;
